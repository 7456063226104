<template lang="pug">
.bar_chart.bar_social_191.content_asset
  a.uevi(:href='assetUrl' target='_blank' :style='horizontalGradient')
    Logo
    | {{assetLink}}
  .header
    figure(v-html='content_asset.account.svg_logo')
  .caption {{content_asset.question.the_question}}
  BarGuts(:stats='content_asset.answer_stats' :total='content_asset.effective_response_count' :account='content_asset.account')
</template>
<script>
import BarGuts from './BarGuts.vue'
import Logo from './graphics/Logo.vue'
import AvatarIcon from './graphics/AvatarIcon.vue'
import { gradientColors } from '@lib/colors'
import { generateShareLink } from '@lib/share_link'

export default {
  name: 'StatlSocial191',
  components: { BarGuts, Logo, AvatarIcon },
  props: ['content_asset'],
  computed: {
    assetLink() {
      return `uevi.co/${this.content_asset.identifier}`
    },
    assetUrl() {
      return generateShareLink(this.conntent_asset.identifier)
    },
    horizontalGradient() {
      const [startColor, endColor] = this.gradientColors
      return {
        background: `linear-gradient(90deg, ${startColor}, ${endColor})`,
      }
    },
    account() {
      return this.content_asset.account
    },
    gradientColors,
  },
}
</script>
<style lang="sass" scoped>
.bar_social_191
  background: white
  width: 520px
  height: 520px
  padding: 32px
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  a.uevi
    position: absolute
    right: 0
    font-weight: 800
    font-size: 9px
    border-radius: 20px 0 0 20px
    line-height: 1
    padding: 6px 48px 6px 6px
    color: white
    display: flex
    align-items: center
    svg::v-deep
      width: 12px
      height: 12px
      margin-right: 12px
      path
        fill: hsla(0, 0%, 100%, 0.6) !important
  .header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 32px
    figure
      height: 24px
      margin: 0
      padding: 0
  .caption
    margin-bottom: 32px
  .bar_guts, .bar_group
    height: 300px



.ueid_container .url a
  font-weight: 800
.legend .legendy
  font-weight: 800
</style>
