import * as amplitude from '@amplitude/analytics-browser'
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser'

const amplitudeConfig = {
  defaultTracking: true,
}

/* set up amplitude tracking.   */
export const setAmplitude = () => {
  let key = 'e2ef560f39b9fd34d6a88c6408a34322'

  if (!window.location.host.includes('app.userevidence.com')) {
    key = 'b8604295517b15149875e298dca883bc'
  }
  amplitude.init(key, amplitudeConfig)
  amplitude.add(autocapturePlugin())
}

/* Track a user event in Amplitude.  This function should be called to track events in Pendo. */
export const amplitudeTrack = (eventName, properties) => {
  amplitude.track(eventName, properties)
}
