<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0716 2.32118C15.4068 1.48304 16.5933 1.48305 16.9285 2.3212L20.3199 10.8C20.4718 11.1796 20.8395 11.4286 21.2484 11.4286H29.3385C30.2619 11.4286 30.6919 12.5731 29.997 13.1811L23.3459 19.0008C23.0486 19.261 22.9323 19.6717 23.0491 20.0491L25.9416 29.3941C26.2169 30.2838 25.2336 31.0337 24.4484 30.5328L16.5379 25.486C16.2099 25.2767 15.7902 25.2767 15.4622 25.486L7.55163 30.5329C6.7665 31.0338 5.78314 30.2838 6.05849 29.3942L8.95075 20.0491C9.06754 19.6717 8.95125 19.261 8.65396 19.0008L2.00288 13.1811C1.30798 12.5731 1.73802 11.4286 2.66139 11.4286H10.7515C11.1604 11.4286 11.5281 11.1796 11.68 10.8L15.0716 2.32118Z"
      :fill="color1"
    />
  </svg>
</template>
<script lang="ts">
export default {
  props: ['brand_color_1', 'brand_color_2'],
  computed: {
    color1() {
      return this.brand_color_1 || '#FFD400'
    },
    color2() {
      return this.brand_color_2 || '#FFD40050'
    },
  },
}
</script>
<style lang="sass" scoped></style>
