<template>
  <div id="accounts_show" class="accounts_show">
    <header
      class="user-research-library-company-header"
      :class="{ dark_text: useDarkText }"
      :style="css_vars"
    >
      <div class="back-link-container" v-if="!isEmbedded">
        <router-link class="back-link" :to="`/user-research-library`">
          <BackArrow></BackArrow><span>Library Home</span></router-link
        >
      </div>
      <div v-if="account" class="header-text-container">
        <div v-if="account.svg_logo" class="logo" v-html="account.svg_logo"></div>
        <h1 v-else>{{ account.name }}</h1>
        <h4>Research Library</h4>
      </div>
    </header>
    <main>
      <section class="uerl_filters">
        <div class="left_side_filters">
          <BFormInput
            v-model="q"
            class="search_input"
            placeholder="Filter by Respondent Name, Company, Testimonial, etc."
            debounce="500"
          ></BFormInput>
        </div>
        <div class="right_side_filters">
          <BFormSelect v-model="type" :options="typeFilter"></BFormSelect>
          <BFormSelect
            v-model="sector_id"
            :options="the_sector_filters"
            :disabled="sector_filters && sector_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="size_group"
            :options="the_size_group_filters"
            :disabled="size_group_filters && size_group_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="seniority"
            :options="the_seniority_filters"
            :disabled="seniority_filters && seniority_filters.length == 0"
          ></BFormSelect>
          <BFormSelect
            v-model="recipient_role_id"
            :options="the_recipient_role_filters"
            :disabled="recipient_role_filters && recipient_role_filters.length == 0"
          ></BFormSelect>
        </div>
      </section>
      <section v-if="initialized" class="asset_container">
        <div
          v-if="content_assets && content_assets.length > 0"
          class="assets"
          :class="{ few: few }"
        >
          <div v-for="asset in content_assets" :key="asset.id" class="asset">
            <router-link v-if="!isEmbedded" :to="assetUrl(asset)">
              <TestimonialBasic
                v-if="asset.type == 'RenderableTestimonial'"
                :renderable-testimonial="asset"
                :account="account"
                renderAsList
              ></TestimonialBasic>
              <BarChartBasic
                v-else-if="asset.type == 'ChartAsset'"
                :content-asset="theAsset(asset)"
                renderAsList
              ></BarChartBasic>
              <StatAsset
                v-else-if="asset.type == 'StatAsset'"
                :content-asset="theAsset(asset)"
                renderAsList
              ></StatAsset>
              <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset"></Microsite>
              <SpotlightPreview
                v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
                :content_asset="theAsset(asset)"
                :customer_spotlight="asset"
                :account="account"
              ></SpotlightPreview>
              <MatrixxChartBasic
                v-else-if="asset.type == 'MatrixxChart'"
                class="as_list"
                :matrixxChart="theAsset(asset)"
                :account="account"
                renderAsList
              ></MatrixxChartBasic>
            </router-link>
            <div v-else>
              <TestimonialBasic
                v-if="asset.type == 'RenderableTestimonial'"
                :renderable-testimonial="asset"
                :account="account"
              ></TestimonialBasic>
              <BarChartBasic
                v-else-if="asset.type == 'ChartAsset'"
                :content-asset="theAsset(asset)"
              ></BarChartBasic>
              <StatAsset
                v-else-if="asset.type == 'StatAsset'"
                :content-asset="theAsset(asset)"
              ></StatAsset>
              <Microsite v-else-if="asset.type == 'AssetList'" :asset_list="asset"></Microsite>
              <SpotlightPreview
                v-else-if="['CustomerSpotlight', 'SurveySpotlightAsset'].includes(asset.type)"
                :content_asset="theAsset(asset)"
              ></SpotlightPreview>
              <MatrixxChartBasic
                v-else-if="asset.type == 'MatrixxChart'"
                :matrixxChart="theAsset(asset)"
                :account="account"
              ></MatrixxChartBasic>
            </div>
          </div>
        </div>
        <div v-else class="assets none">
          <h4>No {{ type_string }} Yet</h4>
          <p>We're currently collecting survey results and will have content assets ready soon.</p>
        </div>
        <Pagination
          v-if="content_assets && content_assets.length > 0"
          v-model="page"
          :records="total_count"
          :per-page="25"
        ></Pagination>
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import TestimonialBasic from '../../../components/TestimonialBasic'
import MatrixxChartBasic from '../../../components/MatrixxChartBasic'
import BarChartBasic from '../../../components/BarChartBasic'
import StatAsset from '../../../components/StatBasic'
import Microsite from '../../../app/components/Microsite'
import SpotlightPreview from '../../../components/SpotlightPreview'
import BackArrow from '../../../components/graphics/BackArrow'
import Pagination from 'vue-pagination-2'
import filters from '../../../mixins/filters.js'
import url_manager from '../../../mixins/url_manager'
import find from 'lodash/find'
import { gradientColors, useDarkText } from '@lib/colors'
import { useAccountStore } from 'src/app/stores/AccountStore'
import ahoy from 'ahoy.js'

export default {
  components: {
    TestimonialBasic,
    BarChartBasic,
    StatAsset,
    Microsite,
    SpotlightPreview,
    BackArrow,
    Pagination,
    MatrixxChartBasic,
  },
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [filters, url_manager],
  data() {
    return {
      account: null,
      content_assets: [], // this list can contain RenderableTestimonials too
      count: null,
      total_count: null,
      typeFilter: [
        { value: '', text: 'All Asset Types' },
        { value: 'ChartAsset', text: 'Charts' },
        { value: 'CustomerSpotlight', text: 'Customer Spotlights' },
        { value: 'RenderableTestimonial', text: 'Testimonials' },
        { value: 'StatAsset', text: 'Stats' },
        { value: 'SpotlightAsset', text: 'Reports' },
        { value: 'MatrixxChart', text: 'Matrix Charts' },
        { value: 'AssetList', text: 'Microsites' },
      ],
    }
  },
  head() {
    return {
      title: `${this.account.name} UserEvidence Research Library | UserEvidence`,
      meta: [{ hid: 'theme-color', name: 'theme-color', content: this.account?.brand_color_1 }],
    }
  },
  computed: {
    few() {
      return this.content_assets?.length < 5
    },
    css_vars() {
      const [startColor, endColor] = this.gradientColors
      return {
        '--header-gradient': `linear-gradient(${startColor}, ${endColor})`,
      }
    },
    colorsFlagEnabled() {
      return this.account?.flipper_features.includes('colors')
    },
    type_string() {
      const defaultStr = 'Content Assets'
      if (this.type?.length === 0) return defaultStr

      // Find proper type string text from typeFilter constant list
      const type = find(this.typeFilter, (t) => t.value == this.type)
      return type?.text
    },
    asset_url() {
      return `/api/accounts/${this.$route.params.id}/content_assets.json`
    },
    filter_url() {
      return `/api/accounts/${this.$route.params.id}/asset_filters.json`
    },
    gradientColors,
    useDarkText,
  },
  watch: {
    q() {
      this.getContentAssets()
    },
    page() {
      this.getContentAssets()
    },
    type() {
      this.getContentAssets()
    },
    size_group() {
      this.getContentAssets()
    },
    sector_id() {
      this.getContentAssets()
    },
    seniority() {
      this.getContentAssets()
    },
    recipient_role_id() {
      this.getContentAssets()
    },
  },
  mounted() {
    axios
      .get(`/api/accounts/${this.$route.params.id}.json`)
      .then((r) => (this.account = r.data.account))
    this.sort_by = 'updated_at'
    this.sort_dir = 'desc'
    this.getContentAssets()

    // we are doing this here to get the app json for koala tracking.
    // note this request will fail if the user is not logged in via the app.
    // be aware that we'll be seeing 401's in the logs because of this
    useAccountStore().getApp()

    ahoy.trackView()
  },
  methods: {
    theAsset(asset) {
      return Object.assign(asset, { account: this.account })
    },
    assetUrl(asset) {
      return asset.type == 'AssetList' ? `/sites/${asset.slug}` : `/assets/${asset.identifier}`
    },
    getContentAssets() {
      Promise.all([
        axios.get(this.asset_url, { params: this.the_query }),
        axios.get(this.filter_url, { params: this.the_query }),
      ]).then((r) => {
        this.content_assets = r[0].data.content_assets
        this.count = r[0].data.count
        this.total_count = r[0].data.total_count
        this.size_group_filters = r[1].data.size_group_filters
        this.sector_filters = r[1].data.sector_filters
        this.seniority_filters = r[1].data.seniority_filters
        this.recipient_role_filters = r[1].data.recipient_role_filters
        this.initialized = true
      })
    },
  },
}
</script>
<style lang="sass" scoped>
header
  background: url('../../../../images/home/ue-email-signup-background-shape.svg'), var(--header-gradient) !important
  background-repeat: no-repeat
</style>
