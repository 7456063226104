import { render, staticRenderFns } from "./TestimonialBasic.vue?vue&type=template&id=dd5d9448&scoped=true&lang=html"
import script from "./TestimonialBasic.vue?vue&type=script&lang=js"
export * from "./TestimonialBasic.vue?vue&type=script&lang=js"
import style0 from "./TestimonialBasic.vue?vue&type=style&index=0&id=dd5d9448&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5d9448",
  null
  
)

export default component.exports