import { render, staticRenderFns } from "./BarSocial.vue?vue&type=template&id=55df688d&scoped=true&lang=pug"
import script from "./BarSocial.vue?vue&type=script&lang=js"
export * from "./BarSocial.vue?vue&type=script&lang=js"
import style0 from "./BarSocial.vue?vue&type=style&index=0&id=55df688d&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55df688d",
  null
  
)

export default component.exports