<template lang="pug">
  .asset_presenter(v-if='renderable_testimonial')
    TestimonialSocial191.crimp(
      :renderableTestimonial='renderable_testimonial'
      :shape="shape"
      :show_all_pages="show_all_pages"
      :page="page"
      :account='account'
      v-if='style == "styled"'
    )
    TestimonialBranded.crimp(
      :renderableTestimonial='renderable_testimonial'
      :backgroundColorOption='backgroundColorOption'
      :account='account'
      v-if='style == "branded"'
    )
    TestimonialBasic(
      :renderable-testimonial='renderable_testimonial'
      :shape="shape"
      :showAllPages="show_all_pages"
      :page="page"
      :account="account"
      v-if='style == "basic"')
    TestimonialCustom.crimp(
      :renderable-testimonial='renderable_testimonial'
      :shape="shape"
      :show_all_pages="show_all_pages"
      :page="page"
      :account="account"
      :theme="theme"
      v-if='style.match(/^custom/)'
    )
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'ChartAsset'")
    BarChartBasic.crimp(
      :content-asset='content_asset'
      :chartColorOption='stat_color_option'
      v-if='design == "basic"'
    )
    BarChartBranded.crimp(
      :content-asset='content_asset'
      :chartColorOption='stat_color_option'
      :backgroundColorOption='background_color_option'
      v-if='design == "branded"'
    )
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'StatAsset'")
    StatBasic.crimp(:content-asset='content_asset' :statColorOption='stat_color_option' v-if='design=="basic"')
    StatBranded.crimp(:content-asset='content_asset' :statColorOption='stat_color_option' :backgroundColorOption='background_color_option' v-if='design == "branded"')
  .asset_presenter(v-else-if='matrixx_chart')
    MatrixxChartBranded.crimp(:matrixxChart='matrixx_chart' :account='matrixx_chart.account' :statColorOption='stat_color_option' :backgroundColorOption='background_color_option' v-if="style == 'branded'")
    MatrixxChartBasic.crimp(:matrixxChart='matrixx_chart' :statColorOption='stat_color_option' :account='matrixx_chart.account' v-else)
  .asset_presenter(v-else-if='content_asset', :class='{ customer_spotlight_multi_page_presenter: variant.type.indexOf("SpotlightMultiPagePngVariant") >= 0 }')
    BarSocial(:content_asset='content_asset' v-if='variant.type == "ChartSocial11PngVariant"')
    BarChartBasic.crimp(:content-asset='content_asset' v-if='variant.type == "ChartPngVariant"')
    StatSocial191.crimp(:content_asset='content_asset' v-if='variant.type == "StatSocial191PngVariant"')
    StatBasic.crimp(:content-asset='content_asset' v-if='variant.type == "StatPngVariant"')
    CustomerSpotlight(:content_asset='content_asset' :horizontal='true'  v-if='variant.type == "PdfVariant" && content_asset.type == "CustomerSpotlightAsset"')
    SpotlightMultiPage(:content_asset='content_asset', :type='variant.type' v-if='variant.type.indexOf("SpotlightMultiPage") >= 0')
    SpotlightSocialTitle(:content_asset='content_asset' :type='variant.type' v-if='/.*Spotlight1[1,9]*TitlePngVariant/.test(variant.type)')
    SurveySpotlight(:content_asset='content_asset' :horizontal='true' v-if='variant.type == "PdfVariant" && content_asset.type == "SurveySpotlightAsset"')
</template>
<script lang="ts">
import StatBasic from '@components/StatBasic.vue'
import StatBranded from '@components/StatBranded.vue'
import BarSocial from '../../components/BarSocial'
import StatSocial191 from '../../components/StatSocial191'
import TestimonialSocial191 from '../../components/TestimonialSocial191.vue'
import TestimonialBasic from '../../components/TestimonialBasic.vue'
import TestimonialBranded from '../../components/TestimonialBranded.vue'
import TestimonialCustom from '../../components/TestimonialCustom.vue'
import BarChartBasic from '@components/BarChartBasic'
import BarChartBranded from '@components/BarChartBranded'
import CustomerSpotlight from '../../components/CustomerSpotlight'
import SpotlightMultiPage from '../../components/SpotlightMultiPage'
import SpotlightSocialTitle from '../../components/SpotlightSocialTitle'
import SurveySpotlight from '../../components/SurveySpotlight'
import MatrixxChartBasic from '../../components/MatrixxChartBasic'
import MatrixxChartBranded from '../../components/MatrixxChartBranded'

export default {
  components: {
    BarSocial,
    BarChartBranded,
    StatBranded,
    StatSocial191,
    StatBasic,
    TestimonialBasic,
    TestimonialSocial191,
    TestimonialBranded,
    TestimonialCustom,
    CustomerSpotlight,
    SpotlightMultiPage,
    SpotlightSocialTitle,
    SurveySpotlight,
    MatrixxChartBasic,
    BarChartBasic,
    MatrixxChartBranded,
  },
  props: [
    'variant',
    'content_asset',
    'shape',
    'show_all_pages',
    'page',
    'renderable_testimonial',
    'design',
    'account',
    'theme',
    'matrixx_chart',

    // the following props are used by BrandedAssets
    'stat_color_option',
    'background_color_option',
  ],
  computed: {
    style() {
      if (this.design.includes('branded_')) {
        return 'branded'
      }

      return this.design
    },
    backgroundColorOption() {
      switch (this.design) {
        case 'branded_primary':
          return 'primary'
        case 'branded_secondary':
          return 'secondary'
        case 'branded_primary_and_secondary':
          return 'both'
        default:
          return undefined
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.crimp
  width: 624px !important
.customer_spotlight_multi_page_presenter
  display: inline-block
  width: 99999px
</style>
